<template lang="pug">
div(
    v-if="!!order"
)
    //- name bar
    TopBar
        div
            IonText(
                color="secondary"
            )
                h1 {{ order.id }} {{ order.cause }}
            IonText(
                color="tertiary"
            )
                div Rapport

    //- boxes
    Container
        //- style="background-color: var(--ion-color-white)"
        Spacer(
            small
        )
        IonRow(class="receding")
            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="primary"
                )
                    h3(class="with-margin") Kunde
                Box(
                    style="background-color: var(--ion-color-quaternary)"
                )
                    IonText(
                        color="primary"
                    )
                        div(class="mb-2") Frau Karin Weimann
                        div Wielandstrasse 13
                        div 70130 Stuttgart

            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="primary"
                )
                    h3(class="with-margin") Rapportdaten
                Box(
                    style="background-color: var(--ion-color-quaternary)"
                )
                    IonText(
                        color="primary"
                    )
                        div(class="mb-2") Monteur:
                        div Datum:

        Spacer(
            small
        )

    //- jobs
    div
        OrderLines(
            :order="order"
            noAddButton
            noEditButtons
            light
            priceTags
        )

    //- signature
    Container(
        style="background-color: var(--ion-color-quinary)"
    )
        Spacer
        IonText(
            color="primary"
        )
            h3 Unterschrift Kunde
        Spacer(
            small
        )
        //- div(
        //-     class="radius"
        //-     style="background-color: white; height: 300px"
        //- )
        SignatureModule(
            @updateSignatureExists="setSignatureExists"
            v-if="$route.name == 'order-report'"
        )
        Spacer(
            small
        )

    //- buttons
    div(class="buttons-height-2")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding")
        //-             IonCol(
        //-                 size="12"
        //-             )
        //-                 IonButton(
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="reportSendModal = true"
        //-                     :disabled="!nextStepAllowed"
        //-                     color="secondary"
        //-                     :style="!nextStepAllowed ? { opacity: '0.3' } : {}"
        //-                 )
        //-                     //- :color="nextStepAllowed ? 'secondary' : 'secondary'"
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Weiter zur Zahlung
    CustomModal(
        v-model="reportSendModal"
    )
        EmailSendModalContent(
            :order="order"
            @sendEmail="sendReport"
            text="Rapport per e-mail senden an"
        )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import OrderName from "@/components/molecules/OrderName"
import CheckableButton from "@/components/molecules/CheckableButton"
import CustomRow from "@/components/molecules/CustomRow"
import Box from "@/components/molecules/Box"
import OrderLines from "@/components/molecules/OrderLines"
import SignatureModule from "@/components/molecules/SignatureModule"
import CustomModal from "@/components/molecules/modals/CustomModal"
import EmailSendModalContent from "@/components/molecules/modals/EmailSendModalContent"
import { chevronForwardCircle } from "ionicons/icons"
import { useUtilities } from "@/composables/utilities"
import { ref, computed, watch } from "vue"
import { useRouter } from "vue-router"
import { useBtnController } from "@/composables/buttons-controller"
export default {
    components: {
        TopBar,
        OrderName,
        CheckableButton,
        Box,
        CustomRow,
        OrderLines,
        SignatureModule,
        CustomModal,
        EmailSendModalContent
    },
    props: {
        order: {
            required: true
        }
    },

    setup(props) {
        chevronForwardCircle
        const tab = ref("cause")

        // bus
        const emitter = useUtilities().getEmitter()

        // router
        const router = useRouter()

        // report sending
        const reportSendModal = ref()

        // signature
        const signatureExists = ref()
        function setSignatureExists(val) {
            signatureExists.value = val
        }

        const nextStepAllowed = computed(() => {
            return signatureExists.value
        })

        const { setOrderReportNextBtn } = useBtnController()

        watch(
            () => nextStepAllowed.value,
            (val) => setOrderReportNextBtn(val),
            { immediate: true }
        )

        emitter.on("openReportSendModal", () => {
            reportSendModal.value = true
        })

        function sendReport() {
            reportSendModal.value = false

            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
            setTimeout(() => {
                router.push({
                    name: "order-payment",
                    params: { id: props.order.id }
                })
            }, 1000)
        }

        return {
            tab,
            chevronForwardCircle,
            reportSendModal,
            sendReport,
            setSignatureExists,
            nextStepAllowed
        }
    }
}
</script>